import { faRefresh, faWarning } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import Checkbox from "../../components/inputs/Checkbox";
import InputOLD from "../../components/inputs/InputOLD";
import WeekDaySelector from "../../components/inputs/WeekDaySelector";
import Badge from "../../components/UI/Badge";
import ProgressBar from "../../components/UI/ProgressBar";
import Table from "../../containers/Table";
import ToursDragDrop from "../../fleet-planner/ToursDragDrop";
import useActiveTours from "../../hooks/data/useActiveTours";
import useStopDrafts from "../../hooks/data/useStopDrafts";
import { useFleetRoute } from "../../hooks/route/useFleetRoute";
import ToursMap from "../../maps/ToursMap";
import { StopDraft } from "../../shared/types/api";
import { dateToString } from "../../shared/utility/date";
import { getCombinedStops } from "../../shared/utility/stop-draft";
import {
    getNextStop,
    getTourEndDate,
    getTourProgress,
} from "../../shared/utility/tour";
import "./style.scss";
import Button from "../../components/buttons/Button";

type Props = {};

function BigVolume(props: Props) {
    const { t } = useTranslation();
    const { filterDate, setFilterDate } = useFleetRoute();
    const {
        stopDrafts,
        refetch: refetchStopDrafts,
        isFetching: isFetchingStopDrafts,
    } = useStopDrafts();
    const {
        tours,
        refetch: refetchTours,
        isFetching: isFetchingTours,
    } = useActiveTours(filterDate ? dateToString(filterDate) : undefined);
    const [selectedStops, setSelectedStops] = useState<StopDraft[]>([]);
    const [selectedTourIds, setSelectedTourIds] = useState<number[]>([]);

    useEffect(() => {
        if (!tours) {
            setSelectedTourIds([]);
            return;
        }

        setSelectedTourIds((state) =>
            state.filter((id) => !tours.some((t) => t.tour_id === id))
        );
    }, [tours]);

    const unhandledStops = useMemo(() => {
        if (!stopDrafts) return [];

        return getCombinedStops(stopDrafts, {
            noExternalStops: true,
            filterDate: filterDate || undefined,
            noMovedAcceptedStops: true,
        });
    }, [filterDate, stopDrafts]);

    const tourCreatedHandler = useCallback(() => {
        refetchStopDrafts();
        refetchTours();
        setSelectedStops([]);
    }, [refetchStopDrafts, refetchTours]);

    const tourUpdatedHandler = useCallback(() => {
        refetchStopDrafts();
        refetchTours();
    }, [refetchStopDrafts, refetchTours]);

    return (
        <div className="big-volume">
            <div className="controls">
                <WeekDaySelector value={filterDate} onChange={setFilterDate} />

                <Button
                    variant="secondary"
                    label={t("activeFleet.updateAllData")}
                    leadingIcon={faRefresh}
                    onClick={() => {
                        refetchStopDrafts();
                        refetchTours();
                    }}
                    isLoading={isFetchingStopDrafts || isFetchingTours}
                    style={{
                        marginLeft: "auto",
                    }}
                />
            </div>
            <div className="content">
                <section className="table-section">
                    <div className="controls">
                        <InputOLD
                            type={"text"}
                            value={""}
                            onChange={() => {}}
                        />
                    </div>
                    {tours && (
                        <Table
                            data={tours}
                            maxHeight="500px"
                            columns={[
                                {
                                    key: "tour_id",
                                    title: "",
                                    render: (tour) => (
                                        <Checkbox
                                            checked={selectedTourIds.includes(
                                                tour.tour_id
                                            )}
                                            onChange={(newChecked) =>
                                                setSelectedTourIds((state) =>
                                                    newChecked
                                                        ? [
                                                              ...state,
                                                              tour.tour_id,
                                                          ]
                                                        : state.filter(
                                                              (id) =>
                                                                  id !==
                                                                  tour.tour_id
                                                          )
                                                )
                                            }
                                        />
                                    ),
                                },
                                {
                                    key: "preferred_driver",
                                    title: t("bigVolume.driver"),
                                },
                                {
                                    key: "status",
                                    title: t("bigVolume.tourStatus"),
                                    render: (tour) => (
                                        <ProgressBar
                                            progress={
                                                getTourProgress(tour).progress
                                            }
                                            maxWidth="100px"
                                        />
                                    ),
                                },
                                {
                                    key: "end_time",
                                    title: t("bigVolume.tourEndTime"),
                                    getValue: (tour) =>
                                        dateToString(
                                            getTourEndDate(tour.stops),
                                            {
                                                onlyTime: true,
                                            }
                                        ) || "",
                                },
                                {
                                    key: "orders",
                                    title: t("bigVolume.orders"),
                                    getValue: (tour) => tour.stops.length / 2,
                                },
                                {
                                    key: "risk",
                                    title: t("bigVolume.tourRisk"),
                                    render: () =>
                                        Math.random() > 0.5 ? (
                                            <FontAwesomeIcon icon={faWarning} />
                                        ) : null,
                                },
                                {
                                    key: "next_stop",
                                    title: t("bigVolume.nextStop"),
                                    getValue: (tour) =>
                                        getNextStop(tour)?.to_location || "",
                                },
                            ]}
                        />
                    )}
                </section>

                <section>
                    {tours && (
                        <ToursMap
                            tours={tours.filter((t) =>
                                selectedTourIds.includes(t.tour_id)
                            )}
                            stops={selectedStops}
                        />
                    )}
                </section>

                <section className="table-section">
                    <div className="controls">
                        <InputOLD
                            type={"text"}
                            value={""}
                            onChange={() => {}}
                        />
                    </div>
                    <Table
                        data={unhandledStops}
                        maxHeight="500px"
                        columns={[
                            {
                                key: "id",
                                title: "",
                                render: ({ pickup, dropoff }) => (
                                    <Checkbox
                                        checked={selectedStops.some(
                                            (s) => s.id === pickup.id
                                        )}
                                        onChange={(newChecked) =>
                                            setSelectedStops((state) =>
                                                newChecked
                                                    ? [
                                                          ...state,
                                                          pickup,
                                                          dropoff,
                                                      ]
                                                    : state.filter(
                                                          (stop) =>
                                                              stop.id !==
                                                                  pickup.id &&
                                                              stop.id !==
                                                                  dropoff.id
                                                      )
                                            )
                                        }
                                    />
                                ),
                            },
                            {
                                key: "to_location",
                                title: t("bigVolume.loading"),
                                getValue: ({ pickup }) => pickup.to_location,
                            },
                            {
                                key: "to_location",
                                title: t("bigVolume.unloading"),
                                getValue: ({ dropoff }) => dropoff.to_location,
                            },
                            {
                                key: "order_number",
                                title: t("bigVolume.orderNumber"),
                                getValue: ({ pickup }) => pickup.order_number,
                            },
                            {
                                key: "weight_kg",
                                title: t("bigVolume.weight"),
                                getValue: ({ pickup }) =>
                                    pickup.weight_kg
                                        ? `${pickup.weight_kg.toString()} kg`
                                        : "",
                            },
                            {
                                key: "tag",
                                title: t("bigVolume.tag"),
                                render: ({ pickup }) =>
                                    pickup.tag ? (
                                        <Badge
                                            title={pickup.tag}
                                            colors={{
                                                background:
                                                    "var(--color-neutral-100)",
                                                text: "var(--color-neutral-600)",
                                            }}
                                        />
                                    ) : null,
                            },
                        ]}
                    />
                </section>

                <section className="drag-drop-section">
                    {tours && (
                        <ToursDragDrop
                            tours={tours.filter((t) =>
                                selectedTourIds.includes(t.tour_id)
                            )}
                            stops={selectedStops}
                            setStops={setSelectedStops}
                            filterDate={filterDate}
                            onCreateTour={tourCreatedHandler}
                            onUpdateTour={tourUpdatedHandler}
                        />
                    )}
                </section>
            </div>
        </div>
    );
}

export default BigVolume;
