import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError, isAxiosError } from "axios";
import { useCallback, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { getMe, inviteCustomer, updateProfile } from "../../api/auth";
import alrikText from "../../assets/icons/alrik-text.svg";
import HamburgerToggle from "../../components/UI/HamburgerToggle";
import ButtonOLD from "../../components/buttons/ButtonOLD";
import IconButton from "../../components/buttons/IconButton";
import TextButton from "../../components/buttons/TextButton";
import DropdownOLD from "../../components/inputs/DropdownOLD";
import InputOLD from "../../components/inputs/InputOLD";
import Popup from "../../hoc/Popup";
import { InviteColleagueForm, inviteColleagueForm } from "../../schemas/auth";
import { User } from "../../shared/types/api";
import { ReduxState } from "../../shared/types/redux";
import { getVersion, onFormError } from "../../shared/utility/misc";
import { API_ERROR, ROUTE } from "../../shared/values/enums";
import { setUser } from "../../store/slices/auth";
import "./style.scss";

type Props = {};

function Navbar(props: Props) {
    const { t } = useTranslation();
    const { user } = useSelector((state: ReduxState) => state.auth);
    const dispatch = useDispatch();
    const queryClient = useQueryClient();

    const {
        control,
        handleSubmit,
        reset: resetInputs,
    } = useForm<InviteColleagueForm>({
        resolver: zodResolver(inviteColleagueForm),
        defaultValues: {
            email: "",
        },
    });

    const [isSidebarOpen, setIsSidebarOpen] = useState(false);

    const navItems = [
        {
            label: t("navbar.orders"),
            path: ROUTE.Orders,
            needsCompany: true,
        },
        {
            label: t("navbar.tours"),
            path: ROUTE.Fleet,
            needsCompany: true,
        },
        {
            label: t("navbar.support"),
            path: ROUTE.Support,
            needsCompany: false,
        },
        {
            label: t("navbar.settings"),
            path: ROUTE.Settings,
            needsCompany: false,
            onlyMobile: true,
        },
    ];

    const { mutate: inviteColleagueHandler, isPending } = useMutation({
        mutationFn: async ({ email }: InviteColleagueForm) => {
            await inviteCustomer({ email, source: "connect" });

            return email;
        },
        onSuccess: () => {
            toast.success(t("navbar.inviteSuccess"));
            resetInputs();
        },
        onError: (error) => {
            if (isAxiosError(error)) {
                const axiosError = error as AxiosError<{ detail: string }>;

                if (axiosError.response?.data.detail === API_ERROR.EmailInUse) {
                    toast.error(t("errorMessage.emailAlreadyExists"));
                    return;
                }
            }

            toast.error(t("errorMessage.unknown"));
        },
    });

    const { mutate: changeLocationHandler, isPending: isChangingLocation } =
        useMutation({
            mutationFn: async ({
                locationId,
                user,
            }: {
                locationId: number;
                user: User;
            }) => {
                await updateProfile({
                    firstName: user.first_name,
                    lastName: user.last_name,
                    phone: user.customer_phone,
                    locationId,
                });
            },
            onSuccess: async () => {
                const res = await getMe();

                dispatch(setUser(res.data));

                queryClient.removeQueries({
                    type: "all",
                });

                toast.success(t("successMessage.changeLocation"));
            },
            onError: () => {
                toast.success(t("errorMessage.changeLocationFailed"));
            },
        });

    const handleNavLinkClick = useCallback(
        (event: React.MouseEvent, needsCompany: boolean) => {
            setIsSidebarOpen(false);
            if (!user?.company_entity && needsCompany) {
                toast.error(t("errorMessage.noCompany"));
                event.preventDefault();
                return;
            }
            if (!user?.location_entity && needsCompany) {
                toast.error(t("errorMessage.noLocation"));
                event.preventDefault();
            }
        },
        [t, user?.company_entity, user?.location_entity]
    );

    return (
        <nav className="navbar">
            <section>
                <img
                    className="text-logo"
                    src={alrikText}
                    alt="Alrik Text"
                    style={{
                        height: "30px",
                    }}
                />
                <DropdownOLD
                    value={user?.location_entity?.id.toString() || null}
                    onSelect={({ value }) =>
                        user
                            ? changeLocationHandler({
                                  user,
                                  locationId: +value,
                              })
                            : null
                    }
                    disabled={!!user?.company_entity?.hidden_locations}
                    isLoading={isChangingLocation}
                    width="250px"
                    options={
                        user?.company_entity?.locations
                            .map((l) => ({
                                label: l.name,
                                value: l.id.toString(),
                            }))
                            .sort((a, b) => a.label.localeCompare(b.label)) ||
                        []
                    }
                />
                {user?.company_entity && (
                    <form
                        className="invite-form"
                        onSubmit={handleSubmit(
                            (data) => inviteColleagueHandler(data),
                            onFormError
                        )}
                    >
                        <Controller
                            name="email"
                            control={control}
                            render={({ field: { value, onChange } }) => (
                                <InputOLD
                                    type="email"
                                    value={value}
                                    onChange={onChange}
                                    placeholder={t("navbar.invitePlaceholder")}
                                    width="250px"
                                />
                            )}
                        />
                        <IconButton icon={"plus"} isLoading={isPending} />
                    </form>
                )}
            </section>
            <section>
                {navItems
                    .filter((nav) => !nav.onlyMobile)
                    .map((nav) => (
                        <NavLink
                            className="nav-item"
                            key={nav.label}
                            to={nav.path}
                            onClick={(e) =>
                                handleNavLinkClick(e, nav.needsCompany)
                            }
                        >
                            {({ isActive }) => (
                                <TextButton
                                    label={nav.label}
                                    className="text-sm"
                                    color={
                                        isActive
                                            ? "var(--text-color)"
                                            : "var(--text-color-light)"
                                    }
                                    style={{
                                        textDecoration: "none",
                                        opacity:
                                            !user?.company_entity &&
                                            nav.needsCompany
                                                ? 0.5
                                                : 1,
                                    }}
                                />
                            )}
                        </NavLink>
                    ))}
                <NavLink to={ROUTE.Settings} className="nav-item text-xs">
                    {({ isActive }) => (
                        <IconButton
                            icon="house"
                            style={{
                                backgroundColor: isActive
                                    ? undefined
                                    : "var(--color-pure-white)",
                                color: isActive
                                    ? undefined
                                    : "var(--text-color)",
                                border: "var(--border-primary)",
                            }}
                        />
                    )}
                </NavLink>
            </section>
            <div className="hamburger-wrapper">
                <HamburgerToggle
                    active={isSidebarOpen}
                    onClick={() => setIsSidebarOpen(!isSidebarOpen)}
                />
            </div>
            <Popup
                showPopup={isSidebarOpen}
                onClose={() => setIsSidebarOpen(false)}
            >
                <div className="sidebar-nav">
                    <section>
                        {navItems.map((nav) => (
                            <NavLink
                                key={nav.label}
                                to={nav.path}
                                onClick={(e) =>
                                    handleNavLinkClick(e, nav.needsCompany)
                                }
                            >
                                {({ isActive }) => (
                                    <TextButton
                                        label={nav.label}
                                        className="text-sm"
                                        color={
                                            isActive
                                                ? "var(--text-color)"
                                                : "var(--text-color-light)"
                                        }
                                    />
                                )}
                            </NavLink>
                        ))}
                    </section>
                    <section>
                        <div className="texts">
                            <p className="version text-xs">v{getVersion()}</p>
                            <p>
                                {t("navbar.callUs")}
                                {": "}
                                <a href="tel:010-880 07 84">010-880 07 84</a>
                            </p>
                        </div>
                        {user?.company_entity && (
                            <form
                                className="invite-form"
                                onSubmit={handleSubmit(
                                    (data) => inviteColleagueHandler(data),
                                    onFormError
                                )}
                            >
                                <Controller
                                    name="email"
                                    control={control}
                                    render={({
                                        field: { value, onChange },
                                    }) => (
                                        <InputOLD
                                            type="email"
                                            value={value}
                                            onChange={onChange}
                                            placeholder={t(
                                                "navbar.invitePlaceholder"
                                            )}
                                            width="100%"
                                        />
                                    )}
                                />
                                <ButtonOLD
                                    label={t("navbar.inviteButtonLabel")}
                                    color="secondary"
                                    isLoading={isPending}
                                    width="50%"
                                />
                            </form>
                        )}
                    </section>
                </div>
            </Popup>
        </nav>
    );
}

export default Navbar;
