import { useCallback, useEffect } from "react";
import { MapPoint } from "../../shared/types/internal";
import { useMap } from "@vis.gl/react-google-maps";

function useMapFitBounds({
    points,
    disable,
    boundsPadding,
}: {
    points: MapPoint[];
    disable?: boolean;
    boundsPadding?: number;
}) {
    const map = useMap();

    const fitBoundsHandler = useCallback(
        (points: MapPoint[]) => {
            if (!map) return;

            const bounds = new google.maps.LatLngBounds();

            for (let i = 0; i < points.length; i++) {
                const point = points[i];

                bounds.extend(point.location);
            }

            if (bounds.isEmpty()) return;

            map.fitBounds(bounds, boundsPadding || 0);
        },
        [boundsPadding, map]
    );

    useEffect(() => {
        if (disable) return;
        fitBoundsHandler(points);
    }, [disable, fitBoundsHandler, points]);

    useEffect(() => {
        if (!map || disable) return;
        const listener = google.maps.event.addListenerOnce(
            map,
            "tilesloaded",
            () => fitBoundsHandler(points)
        );

        return () => {
            listener.remove();
        };
    }, [disable, fitBoundsHandler, map, points]);
}

export default useMapFitBounds;
