import { useTranslation } from "react-i18next";
import OrderFile from "../../containers/OrderFile";
import Popup from "../../hoc/Popup";
import {
    CargoForm,
    DeliveryForm,
    TransportForm,
} from "../../pages/FleetManualOrder/schemas";
import NewOrder from "../../pages/NewOrder";
import { OrderDraft, StopDraft } from "../../shared/types/api";
import "./style.scss";

type Props = {
    showPopup: boolean;
    onClose: () => void;
    orderDraft?: OrderDraft;
    stopDrafts?: {
        pickup?: StopDraft;
        delivery?: StopDraft;
    };
    manualOrderData?: DeliveryForm & CargoForm & TransportForm;
    isFromFleetPlanner?: boolean;
    onOrderCreated?: (orderId: number) => void;
};

function NewOrderPopup(props: Props) {
    const { t } = useTranslation();

    return (
        <Popup
            showPopup={props.showPopup}
            onClose={props.onClose}
            title={t("popup.newOrder.title")}
            width="200%"
            dontCloseOnOutsideClick
            overlayComponent={
                props.orderDraft?.file_name ? (
                    <OrderFile filename={props.orderDraft.file_name} />
                ) : undefined
            }
        >
            <div className="new-order-popup">
                <NewOrder
                    noGuide
                    isPopup
                    isFromFleetPlanner={props.isFromFleetPlanner}
                    orderDraft={props.orderDraft}
                    stopDrafts={props.stopDrafts}
                    manualOrderData={props.manualOrderData}
                    onOrderCreated={props.onOrderCreated}
                />
            </div>
        </Popup>
    );
}

export default NewOrderPopup;
