import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Spinner from "../../UI/Spinner";
import "./style.scss";

type Props = {
    label?: string;
    variant: "primary" | "secondary";
    leadingIcon?: IconDefinition;
    leadingIconColor?: string;
    trailingIcon?: IconDefinition;
    onClick?: (event: React.MouseEvent) => void;
    style?: React.CSSProperties;
    className?: string;
    isLoading?: boolean;
    disabled?: boolean;
    noSubmit?: boolean;
};

function Button(props: Props) {
    const buttonClasses = ["button", "waves-effect"];

    if (props.className) {
        buttonClasses.push(props.className);
    }

    return (
        <button
            onClick={(e) => {
                if (props.isLoading || props.disabled) {
                    e.preventDefault();
                    return;
                }
                if (props.onClick) props.onClick(e);
            }}
            className={buttonClasses.join(" ")}
            disabled={props.disabled}
            style={{
                boxShadow: "var(--shadow)",
                backgroundColor:
                    props.variant === "primary"
                        ? "var(--color-primary-400)"
                        : "var(--color-pure-white)",
                border:
                    props.variant === "primary"
                        ? "1px solid var(--color-primary-400)"
                        : "1px solid var(--color-neutral-200)",
                ...props.style,
            }}
            type={props.noSubmit || props.onClick ? "button" : undefined}
        >
            {/* Leading icon */}

            {props.isLoading && (
                <div className="button-loading">
                    <Spinner width="15px" />
                </div>
            )}
            {props.leadingIcon && !props.isLoading && (
                <FontAwesomeIcon
                    icon={props.leadingIcon}
                    size="lg"
                    color={
                        props.leadingIconColor
                            ? props.leadingIconColor
                            : props.variant === "primary"
                            ? "var(--color-primary-600)"
                            : "var(--color-neutral-500)"
                    }
                />
            )}

            {/* Label */}
            {props.label && (
                <p
                    className="text-xs"
                    style={{
                        color:
                            props.variant === "primary"
                                ? "var(--color-primary-900)"
                                : "var(--color-neutral-900)",
                    }}
                >
                    {props.label}
                </p>
            )}

            {/* Trailing icon */}
            {props.trailingIcon &&
                (props.isLoading ? (
                    <div className="button-loading">
                        <Spinner width="15px" />
                    </div>
                ) : (
                    <FontAwesomeIcon
                        icon={props.trailingIcon}
                        size="lg"
                        color={
                            props.variant === "primary"
                                ? "var(--color-primary-600)"
                                : "var(--color-neutral-500)"
                        }
                    />
                ))}
        </button>
    );
}

export default Button;
