import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import IconButton from "../../components/buttons/IconButton";
import Navbar from "../../containers/Navbar";
import "./style.scss";
import AssistantChat from "../../containers/AssistantChat";
import { isEnvironment } from "../../shared/utility/misc";

type Props = {
    children: React.ReactNode;
    excludeNavbarRoutes?: string[];
};

function Layout(props: Props) {
    const location = useLocation();
    const { t } = useTranslation();

    const [showBanner, setShowBanner] = useState(true);

    const hasBanner =
        !!t("general.banner") &&
        showBanner &&
        !props.excludeNavbarRoutes?.includes(location.pathname);

    return (
        <div className="app-wrapper">
            {hasBanner && (
                <div className="banner">
                    <span className="text-xs">{t("general.banner")}</span>
                    <IconButton
                        icon="cross"
                        short
                        style={{
                            backgroundColor: "transparent",
                            color: "var(--text-color)",
                        }}
                        onClick={() => setShowBanner(false)}
                    />
                </div>
            )}
            {props.excludeNavbarRoutes?.includes(location.pathname) ? null : (
                <Navbar />
            )}
            <div className="content">{props.children}</div>
            {isEnvironment("staging") && <AssistantChat />}
        </div>
    );
}

export default Layout;
