import { useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Spinner from "../../components/UI/Spinner";
import ButtonOLD from "../../components/buttons/ButtonOLD";
import InputOLD from "../../components/inputs/InputOLD";
import DriversTable from "../../containers/DriversTable";
import useDrivers from "../../hooks/data/useDrivers";
import DriversMap from "../../maps/DriversMap";
import AddDriverPopup from "../../popups/AddDriverPopup";
import EditDriverPopup from "../../popups/EditDriverPopup";
import { Driver } from "../../shared/types/api";
import { ReduxState } from "../../shared/types/redux";
import { getDriverDisplayName } from "../../shared/utility/misc";
import "./style.scss";
import AddDriverPopupOLD from "../../popups/AddDriverPopupOLD";
import EditDriverPopupOLD from "../../popups/EditDriverPopupOLD";

type Props = {};

function Drivers(props: Props) {
    const { t } = useTranslation();
    const { user } = useSelector((state: ReduxState) => state.auth);
    const { settings } = useSelector((state: ReduxState) => state.fleetPlanner);

    const { drivers, status, refetch, isFetching } = useDrivers({
        driversFromCompany:
            !!user?.company_entity?.show_location_orders &&
            !!settings.showAllDriversInCompany,
    });

    const [isAddDriverOpen, setIsAddDriverOpen] = useState(false);
    const [editDriver, setEditDriver] = useState<Driver | null>(null);
    const [searchString, setSearchString] = useState("");
    const [hiddenSearchDriversIds, setHiddenSearchDriversIds] = useState<
        number[]
    >([]);

    async function updateMapHandler() {
        await refetch();
        toast.success(t("successMessage.driversMapUpdated"));
    }

    function driverSearchHandler(searchString: string) {
        setSearchString(searchString);

        if (!searchString) {
            setHiddenSearchDriversIds([]);
            return;
        }

        setHiddenSearchDriversIds((state) => {
            if (!drivers) return state;

            const newState: number[] = [];

            for (let i = 0; i < drivers.length; i++) {
                const driver = drivers[i];

                const driverName = getDriverDisplayName(driver);

                if (
                    !driverName?.toLowerCase().includes(searchString) &&
                    !driver.email?.toLowerCase().includes(searchString)
                ) {
                    newState.push(driver.id);
                }
            }
            return newState;
        });
    }

    return status !== "success" ? (
        <Spinner padding="10px" />
    ) : (
        <div className="drivers">
            <DriversMap
                drivers={drivers}
                onUpdateDrivers={updateMapHandler}
                isUpdatingDrivers={isFetching}
            />
            <div className="table-controls">
                <InputOLD
                    type="text"
                    icon="search"
                    value={searchString}
                    onChange={driverSearchHandler}
                />
                <ButtonOLD
                    label={t("drivers.addDriver")}
                    color="secondary"
                    onClick={() => setIsAddDriverOpen(true)}
                />
            </div>
            <DriversTable
                drivers={drivers.filter(
                    (driver) => !hiddenSearchDriversIds.includes(driver.id)
                )}
                onEditClick={setEditDriver}
            />
            {user?.location_entity?.use_alrik_driver_app ? (
                <AddDriverPopup
                    showPopup={isAddDriverOpen}
                    onClose={() => setIsAddDriverOpen(false)}
                />
            ) : (
                <AddDriverPopupOLD
                    showPopup={isAddDriverOpen}
                    onClose={() => setIsAddDriverOpen(false)}
                />
            )}
            {editDriver &&
                (user?.location_entity?.use_alrik_driver_app ? (
                    <EditDriverPopup
                        showPopup={!!editDriver}
                        onClose={() => setEditDriver(null)}
                        editDriver={editDriver}
                    />
                ) : (
                    <EditDriverPopupOLD
                        showPopup={!!editDriver}
                        onClose={() => setEditDriver(null)}
                        editDriver={editDriver}
                    />
                ))}
        </div>
    );
}

export default Drivers;
