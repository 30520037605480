import {
    DragDropContext,
    DropResult,
    Droppable,
    ResponderProvided,
} from "@hello-pangea/dnd";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import Spinner from "../../components/UI/Spinner";
import ButtonOLD from "../../components/buttons/ButtonOLD";
import StopDraftDraggable from "../../fleet-planner/cards/StopDraftDraggable";
import Popup from "../../hoc/Popup";
import { StopDraftsTour } from "../../shared/types/api";
import { KeyNumber } from "../../shared/types/internal";
import { formatMinutes } from "../../shared/utility/date";
import {
    cardHoverHandler,
    checkForGroupsAround,
    getRunningWeight,
    getStopListDurationInMinutes,
    getTourStatus,
} from "../../shared/utility/stop-draft";
import TourMap from "../TourMap";
import "./style.scss";

type Props = {
    title: string;
    showPopup: boolean;
    onClose: () => void;
    onDragEnd: (result: DropResult, provided: ResponderProvided) => void;
    onSortClick: () => void;
    droppableId: string;
    isColumnLoading?: boolean;
    tour: StopDraftsTour;
    etaMap?: KeyNumber<string>;
};

function FleetPlannerMap(props: Props) {
    const { t } = useTranslation();

    const { hideEta } = useMemo(() => getTourStatus(props.tour), [props.tour]);

    const tourDuration = useMemo(() => {
        return formatMinutes(getStopListDurationInMinutes(props.tour.stops));
    }, [props.tour]);

    const { peakWeight, runningWeights } = useMemo(() => {
        return getRunningWeight(props.tour.stops);
    }, [props.tour.stops]);

    return (
        <Popup
            title={props.title}
            showPopup={props.showPopup}
            onClose={props.onClose}
            dontCloseOnOutsideClick
            overlayComponent={
                <TourMap mapId="fleet-planner-map" stops={props.tour.stops} />
            }
        >
            <div className="fleet-planner-map-controls">
                <ButtonOLD
                    color="secondary"
                    label={t("createTour.sortByType")}
                    onClick={props.onSortClick}
                    width="250px"
                />
            </div>

            <div className="fleet-planner-map-info">
                <span>
                    {t("fleetPlanner.estimatedTime")} {tourDuration}
                </span>
                <span>
                    {t("fleetPlanner.maxRunningWeight")} {peakWeight} kg
                </span>
            </div>

            <DragDropContext onDragEnd={props.onDragEnd}>
                <Droppable droppableId={props.droppableId}>
                    {(provided) => (
                        <div
                            className="fleet-planner-map"
                            ref={provided.innerRef}
                        >
                            {props.tour.stops.map((sd, i) => (
                                <StopDraftDraggable
                                    key={sd.id}
                                    index={i}
                                    stopDraft={sd}
                                    showPosition
                                    runningWeight={runningWeights[i]}
                                    onHover={(gId) =>
                                        cardHoverHandler({
                                            groupId: gId,
                                            columnSelector:
                                                ".fleet-planner-map",
                                        })
                                    }
                                    eta={
                                        props.etaMap
                                            ? props.etaMap[sd.id]
                                            : undefined
                                    }
                                    hideEta={hideEta}
                                    hasGroupsAround={checkForGroupsAround({
                                        stop: sd,
                                        stopAbove: props.tour.stops[i - 1],
                                        stopBelow: props.tour.stops[i + 1],
                                    })}
                                />
                            ))}
                            {provided.placeholder}
                            {props.isColumnLoading && (
                                <div className="loading-wrapper">
                                    <Spinner />
                                </div>
                            )}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
            <div className="fleet-planner-map-submit-wrapper">
                <ButtonOLD
                    color="secondary"
                    label={t("popup.fleetPlannerMap.submit")}
                    onClick={props.onClose}
                    width="100%"
                />
            </div>
        </Popup>
    );
}

export default FleetPlannerMap;
