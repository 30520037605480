import { useMemo } from "react";
import useDirections from "../../../hooks/functionality/useDirections";
import useMapFitBounds from "../../../hooks/functionality/useMapFitBounds";
import { KeyString, MapPoint } from "../../../shared/types/internal";
import PointsMarker from "../PointsMarker";

type Props = {
    points: MapPoint[];
    disableFitBounds?: boolean;
    boundsPadding?: number;
    directionsColor?: string;
    disableDirections?: boolean;
};

function TourMarkers(props: Props) {
    useMapFitBounds({
        points: props.points,
        disable: props.disableFitBounds,
        boundsPadding: props.boundsPadding,
    });

    useDirections({
        path: props.points.map((point) => point.location),
        directionsColor: props.directionsColor,
        hide: props.disableDirections,
    });

    const latLngStopCounts = useMemo(() => {
        const latLngCounts: KeyString<number> = {};
        for (let i = 0; i < props.points.length; i++) {
            const point = props.points[i];

            const key = `${point.location.lat},${point.location.lng}`;
            latLngCounts[key] = (latLngCounts[key] || 0) + 1;
        }
        return latLngCounts;
    }, [props.points]);

    return (
        <>
            {props.points.map((point, index) => (
                <PointsMarker
                    key={index}
                    point={point}
                    amountAtSameLocation={
                        latLngStopCounts[
                            `${point.location.lat},${point.location.lng}`
                        ]
                    }
                />
            ))}
        </>
    );
}

export default TourMarkers;
