import "./style.scss";

type Props = {
    title: string;
    colors: {
        background: string;
        text: string;
    };
};

function Badge(props: Props) {
    return (
        <div
            className="badge"
            style={{
                backgroundColor: props.colors.background,
                color: props.colors.text,
            }}
        >
            {props.title}
        </div>
    );
}

export default Badge;
