import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { HTMLInputAutoCompleteAttribute } from "react";
import "./style.scss";

type Props = {
    type: "text" | "email" | "password" | "tel" | "number" | "time";
    value: string;
    id?: string;
    onChange: (value: string) => void;
    onClick?: () => void;
    placeholder?: string | null;
    label?: string;
    leadingIcon?: IconDefinition;
    trailingIcon?: IconDefinition;
    disabled?: boolean;
    autoComplete?: HTMLInputAutoCompleteAttribute;
};

function Input(props: Props) {
    return (
        <label className="input" id={props.id}>
            {/* Label above the input */}
            {props.label && <p className="text-xs label">{props.label}</p>}

            {/* Wrapper around the input */}
            <div className="input-wrapper">
                <div>
                    {props.leadingIcon && (
                        <FontAwesomeIcon
                            icon={props.leadingIcon}
                            size="lg"
                            color="var(--color-neutral-400)"
                        />
                    )}
                    <input
                        type={props.type}
                        value={props.value}
                        onChange={(event) => props.onChange(event.target.value)}
                        onClick={props.onClick}
                        placeholder={props.placeholder || undefined}
                        className="text-sm"
                        disabled={props.disabled}
                        autoComplete={props.autoComplete}
                    />
                </div>
                {props.trailingIcon && (
                    <FontAwesomeIcon
                        icon={props.trailingIcon}
                        size="lg"
                        color="var(--color-neutral-400)"
                    />
                )}
            </div>
        </label>
    );
}

export default Input;
