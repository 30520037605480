import { faSquare } from "@fortawesome/pro-regular-svg-icons";
import { faSquareCheck } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./style.scss";

type Props = {
    checked: boolean;
    onChange: (value: boolean) => void;
    disabled?: boolean;
    style?: React.CSSProperties;
};

function Checkbox(props: Props) {
    const checkboxClasses = ["checkbox"];
    if (props.checked) {
        checkboxClasses.push("checked");
    }
    if (props.disabled) {
        checkboxClasses.push("disabled");
    }

    return (
        <label className={checkboxClasses.join(" ")} style={props.style}>
            <input
                type="checkbox"
                checked={props.checked}
                disabled={props.disabled}
                onChange={(e) => {
                    props.onChange(e.target.checked);
                }}
                hidden
            />
            {props.checked ? (
                <FontAwesomeIcon
                    icon={faSquareCheck}
                    size="lg"
                    color="var(--color-primary-400)"
                />
            ) : (
                <FontAwesomeIcon icon={faSquare} size="lg" />
            )}
        </label>
    );
}

export default Checkbox;
