import { DraggableProvided } from "@hello-pangea/dnd";
import { useMemo } from "react";
import Badge from "../../../components/UI/Badge";
import { StopDraft } from "../../../shared/types/api";
import { formatEta } from "../../../shared/utility/date";
import { STOP_DRAFT_TYPE } from "../../../shared/values/enums";
import "./style.scss";

export type StopCardProps = {
    // Drag and Drop
    provided?: DraggableProvided;

    //Core data
    stop: StopDraft;
    index: number;
    runningWeight?: number;
    eta?: string;
};

function StopCard(props: StopCardProps) {
    const etaString = useMemo(() => {
        return formatEta(
            props.stop.completed_at ||
                props.stop.arrived_at ||
                props.stop.eta_internal ||
                undefined
        );
    }, [
        props.stop.arrived_at,
        props.stop.completed_at,
        props.stop.eta_internal,
    ]);

    return (
        <div
            className="stop-card"
            ref={props.provided?.innerRef}
            {...props.provided?.draggableProps}
            {...props.provided?.dragHandleProps}
        >
            <div className="top">
                <Badge
                    title={`${props.index + 1}`}
                    colors={{
                        background: "var(--color-purple-600)",
                        text: "var(--color-pure-white)",
                    }}
                />
                <p className="address text-xs">{props.stop.to_location}</p>
                {props.stop.tag && (
                    <Badge
                        title={props.stop.tag}
                        colors={{
                            background: "var(--color-neutral-100)",
                            text: "var(--color-neutral-600)",
                        }}
                    />
                )}
            </div>
            <div className="info">
                <p className="text-xs">
                    {props.stop.stop_type_id === STOP_DRAFT_TYPE.Pickup
                        ? "PU"
                        : "DO"}
                </p>
                <p className="eta">
                    <i>ETA: </i>
                    {etaString || props.eta || "-"}
                </p>

                {props.stop.weight_kg && (
                    <p className="text-xs">
                        {props.runningWeight !== undefined
                            ? `${
                                  props.stop.stop_type_id ===
                                  STOP_DRAFT_TYPE.Pickup
                                      ? "+"
                                      : "-"
                              }${props.stop.weight_kg} kg (${
                                  props.runningWeight
                              } kg)`
                            : `${props.stop.weight_kg} kg`}
                    </p>
                )}
            </div>
        </div>
    );
}

export default StopCard;
