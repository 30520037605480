import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError, isAxiosError } from "axios";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { inviteDriverOld } from "../../api/driver";
import ButtonOLD from "../../components/buttons/ButtonOLD";
import DropdownOLD from "../../components/inputs/DropdownOLD";
import InputOLD from "../../components/inputs/InputOLD";
import Popup from "../../hoc/Popup";
import useFuelTypes from "../../hooks/data/useFuleTypes";
import { DriverFormOld, driverFormOld } from "../../schemas/form";
import { isEnvironment, onFormError } from "../../shared/utility/misc";
import { API_ERROR } from "../../shared/values/enums";
import "./style.scss";

type Props = {
    showPopup: boolean;
    onClose: () => void;
};

function AddDriverPopupOLD(props: Props) {
    const { t } = useTranslation();
    const queryClient = useQueryClient();

    const { fuleTypes, status: fuelTypesStatus } = useFuelTypes();

    const { handleSubmit, control, reset } = useForm<DriverFormOld>({
        resolver: zodResolver(driverFormOld),
        defaultValues: {
            email: "",
            hourlyCost: "",
            fuelTypeId: null,
            enabled: false,
            fuelConsumption: "",
        },
    });

    const { mutate: addDriverHandler, isPending: isAddingDriver } = useMutation(
        {
            mutationFn: async (data: DriverFormOld) => {
                const formattedFuelConsumption =
                    data.fuelConsumption?.replace(",", ".") || "";
                const formattedHourlyCost =
                    data.hourlyCost?.replace(",", ".") || "";

                await inviteDriverOld({
                    ...data,
                    fuelConsumption: isNaN(+formattedFuelConsumption)
                        ? "0"
                        : formattedFuelConsumption,
                    hourlyCost: isNaN(+formattedHourlyCost)
                        ? "0"
                        : formattedHourlyCost,
                });
            },
            onSuccess: () => {
                toast.success(t("successMessages.driverAdded"));
                queryClient.invalidateQueries({
                    queryKey: ["drivers"],
                    type: "all",
                });
                props.onClose();
            },
            onError: (error) => {
                if (isAxiosError(error)) {
                    const axiosError = error as AxiosError<{ detail: string }>;
                    if (
                        axiosError.response?.data.detail ===
                        API_ERROR.DriverAlreadyExists
                    ) {
                        toast.error(t("errorMessage.driverAlreadyExists"));
                        return;
                    }
                }
                toast.error(t("errorMessage.unknown"));
            },
        }
    );

    useEffect(() => {
        reset();
    }, [props.showPopup, reset]);

    return (
        <Popup
            showPopup={props.showPopup}
            onClose={props.onClose}
            title={
                t("popup.driver.title") +
                (isEnvironment("staging") ? " With MT" : "")
            }
        >
            <div className="add-driver-popup">
                <Controller
                    name="email"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                        <InputOLD
                            type="email"
                            value={value}
                            onChange={onChange}
                            label={t("popup.driver.emailLabel")}
                        />
                    )}
                />
                <Controller
                    name="hourlyCost"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                        <InputOLD
                            type="text"
                            value={value}
                            onChange={onChange}
                            label={t("popup.driver.hourlyCostLabel")}
                        />
                    )}
                />
                <Controller
                    control={control}
                    name="fuelTypeId"
                    render={({ field: { value, onChange } }) => (
                        <DropdownOLD
                            value={value?.toString() || null}
                            onSelect={(o) => onChange(+o.value)}
                            label={t("popup.driver.fuleTypeLabel")}
                            options={
                                fuleTypes?.map((f) => ({
                                    value: f.id.toString(),
                                    label: f.name,
                                })) || []
                            }
                            isLoading={fuelTypesStatus === "pending"}
                            isSearchable
                        />
                    )}
                />
                <Controller
                    name="fuelConsumption"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                        <InputOLD
                            type="text"
                            value={value}
                            onChange={onChange}
                            label={t("popup.driver.fuleConsumptionLabel")}
                        />
                    )}
                />
                <ButtonOLD
                    label={t("popup.driver.submitLabel")}
                    color="secondary"
                    onClick={handleSubmit(
                        (data) => addDriverHandler(data),
                        onFormError
                    )}
                    isLoading={isAddingDriver}
                />
            </div>
        </Popup>
    );
}

export default AddDriverPopupOLD;
