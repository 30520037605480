import { addDays, format, getUnixTime, isWeekend, startOfDay } from "date-fns";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { dateToString } from "../../../shared/utility/date";
import { getLocale } from "../../../shared/utility/misc";
import Subtitle from "../../UI/Subtitle";
import "./style.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/pro-regular-svg-icons";
import IconOLD from "../../UI/IconOLD";

type Props = {
    value: Date | null;
    onChange: (value: Date | null) => void;
    minDateDays?: number;
    maxDateDays?: number;
    placeholder?: string | null;
    width?: string;
    isEnvironmental?: boolean;
    unselectableDates?: string[];
    ignoreUnselectableDates?: boolean;
    showAllDates?: boolean;
    isCompleted?: boolean;
    hideWeekNumbers?: boolean;
    short?: boolean;
    label?: string;
    labelColor?: string;
};

function DateInputOLD(props: Props) {
    const { t } = useTranslation();

    function getMinDate() {
        if (props.showAllDates) {
            return new Date(0);
        }

        return addDays(
            startOfDay(new Date()),
            props.minDateDays === undefined ? 1 : props.minDateDays
        );
    }

    function getMaxDate() {
        if (props.showAllDates) {
            //Feb 24 274493
            return new Date(8600000000000000);
        }

        return addDays(
            startOfDay(new Date()),
            props.maxDateDays === undefined ? 365 * 10 : props.maxDateDays
        );
    }

    const inputDesktopClasses = ["input-desktop"];
    const inputMobileClasses = ["input-mobile"];
    if (props.short) {
        inputDesktopClasses.push("short");
        inputMobileClasses.push("short");
    }
    if (props.isCompleted) {
        inputDesktopClasses.push("is-completed");
        inputMobileClasses.push("is-completed");
    }

    function selectHandler(date: Date | null) {
        if (date === null) {
            props.onChange(null);
            return;
        }

        const minDate = getMinDate();

        if (getUnixTime(date) >= getUnixTime(minDate)) {
            props.onChange(date);
        } else {
            props.onChange(null);
            toast.error(
                `${t("errorMessage.badDate")} ${format(minDate, "yyyy-MM-dd")} `
            );
        }
    }

    return (
        <div
            className="date-picker"
            style={{
                width: props.width,
            }}
        >
            {props.label && (
                <p
                    className="text-xs"
                    style={{
                        color: props.labelColor || "var(--color-neutral-500)",
                        marginBottom: "10px",
                    }}
                >
                    {props.label}
                </p>
            )}
            <DatePicker
                className={inputDesktopClasses.join(" ")}
                selected={props.value}
                onChange={(date) => selectHandler(date)}
                placeholderText={props.placeholder || undefined}
                dateFormat="yyyy-MM-dd"
                minDate={getMinDate()}
                maxDate={getMaxDate()}
                calendarClassName="date-input-calendar"
                wrapperClassName="date-input-wrapper-desktop"
                autoComplete="off"
                locale={getLocale()}
                calendarStartDay={1}
                filterDate={
                    props.ignoreUnselectableDates
                        ? undefined
                        : (date) =>
                              !isWeekend(date) &&
                              !props.unselectableDates?.includes(
                                  dateToString(date)
                              )
                }
                disabledKeyboardNavigation={true}
                showWeekNumbers={!props.hideWeekNumbers}
                popperModifiers={[]}
                popperPlacement="bottom-start"
            />

            <input
                className={inputMobileClasses.join(" ")}
                type="date"
                value={
                    props.value ? props.value.toISOString().split("T")[0] : ""
                }
                onChange={(e) => selectHandler(e.target.valueAsDate)}
                placeholder={props.placeholder || undefined}
                min={getMinDate().toISOString().split("T")[0]}
                autoComplete="off"
            />
            <IconOLD
                type="calendar"
                className="date-picker-icon"
                style={{
                    color: props.isCompleted ? "var(--text-color)" : undefined,
                    pointerEvents: "none",
                    top: props.label ? "calc(50% + 7px)" : undefined,
                }}
            />
            {props.isEnvironmental && (
                <Subtitle
                    text={t("general.co2ScheduleText")}
                    icon="seedling"
                    iconColor="var(--text-color-alt)"
                    iconStyle={{
                        marginRight: "5px",
                    }}
                />
            )}
        </div>
    );
}

export default DateInputOLD;
