import {
    DragDropContext,
    DropResult,
    OnDragEndResponder,
} from "@hello-pangea/dnd";
import { Dispatch, SetStateAction, useCallback } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import ScrollContainer from "react-indiana-drag-scroll";
import useDrivers from "../../hooks/data/useDrivers";
import { StopDraft, StopDraftsTour } from "../../shared/types/api";
import { NEW_TOUR_COLUMN_ID, STOP_DRAFT_TYPE } from "../../shared/values/enums";
import TourColumn from "../columns/TourColumn";
import "./style.scss";

type Props = {
    tours: StopDraftsTour[];
    stops: StopDraft[];
    setStops: Dispatch<SetStateAction<StopDraft[]>>;
    filterDate: Date | null;
    onCreateTour?: () => void;
    onUpdateTour?: () => void;
};

function ToursDragDrop(props: Props) {
    const { setStops } = props;
    const { drivers } = useDrivers();
    const { t } = useTranslation();

    const onReorderNewTour = useCallback(
        (result: DropResult) => {
            if (!result.destination) return;
            const { source, destination } = result;

            setStops((state) => {
                const newSelectedStops = [...state];

                const [removed] = newSelectedStops.splice(source.index, 1);
                newSelectedStops.splice(destination.index, 0, removed);

                const groupedStopIndex = newSelectedStops.findIndex(
                    (sd) =>
                        sd.group_id === removed?.group_id &&
                        sd.id !== removed.id
                );

                if (
                    removed.stop_type_id === STOP_DRAFT_TYPE.Pickup &&
                    destination.index > groupedStopIndex
                ) {
                    toast.error(t("errorMessage.invalidMovePickup"));
                    return state;
                } else if (
                    removed.stop_type_id === STOP_DRAFT_TYPE.Dropoff &&
                    destination.index < groupedStopIndex
                ) {
                    toast.error(t("errorMessage.invalidMoveDropoff"));
                    return state;
                }

                return newSelectedStops;
            });
        },
        [setStops, t]
    );

    const onDragEnd = useCallback<OnDragEndResponder>(
        (result, provided) => {
            if (!result.destination) return;
            const { source, destination } = result;

            //return if the card is dropped in the same place it was picked up
            if (
                destination.droppableId === source.droppableId &&
                destination.index === source.index
            )
                return;

            if (
                source.droppableId === NEW_TOUR_COLUMN_ID &&
                destination.droppableId === NEW_TOUR_COLUMN_ID
            ) {
                onReorderNewTour(result);
                return;
            }
        },
        [onReorderNewTour]
    );

    return (
        <div className="tours-drag-drop">
            <ScrollContainer
                className="content"
                ignoreElements=".stop-card, .dropdown-wrapper-old, .input"
                vertical={false}
                hideScrollbars={false}
            >
                <DragDropContext onDragEnd={onDragEnd}>
                    <TourColumn
                        key={NEW_TOUR_COLUMN_ID}
                        id={NEW_TOUR_COLUMN_ID}
                        drivers={drivers || []}
                        stops={props.stops}
                        filterDate={props.filterDate}
                        isNewTourColumn
                        onCreateTour={props.onCreateTour}
                        onUpdateTour={props.onUpdateTour}
                    />
                    {props.tours.map((tour) => (
                        <TourColumn
                            key={tour.tour_id}
                            id={tour.tour_id.toString()}
                            tour={tour}
                            drivers={drivers || []}
                            stops={tour.stops}
                            filterDate={props.filterDate}
                            onCreateTour={props.onCreateTour}
                            onUpdateTour={props.onUpdateTour}
                        />
                    ))}
                </DragDropContext>
            </ScrollContainer>
        </div>
    );
}

export default ToursDragDrop;
