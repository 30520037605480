import { faClock } from "@fortawesome/pro-regular-svg-icons";
import { Droppable } from "@hello-pangea/dnd";
import { useMutation } from "@tanstack/react-query";
import { addMinutes } from "date-fns";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { createTour, updateTour } from "../../../api/tours";
import Button from "../../../components/buttons/Button";
import DropdownOLD from "../../../components/inputs/DropdownOLD";
import Input from "../../../components/inputs/Input";
import { Driver, StopDraft, StopDraftsTour } from "../../../shared/types/api";
import { dateToString, stringToDate } from "../../../shared/utility/date";
import { getDriverDisplayName } from "../../../shared/utility/misc";
import {
    getRunningWeight,
    getStopListRealtiveEtaPerIdInMinutes,
} from "../../../shared/utility/stop-draft";
import StopCardDraggable from "../../cards/StopCardDraggable";
import "./style.scss";

type Props = {
    id: string;
    tour?: StopDraftsTour;
    stops: StopDraft[];
    drivers: Driver[];
    filterDate: Date | null;
    isNewTourColumn?: boolean;
    onCreateTour?: () => void;
    onUpdateTour?: () => void;
};

function TourColumn(props: Props) {
    const { t } = useTranslation();

    const [selectedDriverId, setSelectedDriverId] = useState("");
    const [startTime, setStartTime] = useState("06:00");

    useEffect(() => {
        if (!props.tour) return;

        setSelectedDriverId(props.tour.preferred_driver_id);
        setStartTime(props.tour.time || "");
    }, [props.tour]);

    const { runningWeights } = useMemo(() => {
        return getRunningWeight(props.stops);
    }, [props.stops]);

    const stopsDurationsMap = useMemo(() => {
        return getStopListRealtiveEtaPerIdInMinutes(props.stops);
    }, [props.stops]);

    const { mutate: createTourHandler, isPending: isCreatingTour } =
        useMutation({
            mutationFn: async (data: {
                preferredDriverId: string;
                date: string;
                time: string;
                stopDraftIds: number[];
            }) => {
                await createTour({
                    preferred_driver_id: data.preferredDriverId,
                    date: data.date,
                    time: data.time,
                    stop_draft_ids: data.stopDraftIds,
                });
            },
            onSuccess: () => {
                props.onCreateTour?.();
                setSelectedDriverId("");
                setStartTime("06:00");
            },
        });

    return (
        <Droppable droppableId={props.id}>
            {(provided) => (
                <div className="tour-column">
                    <div className="top">
                        <DropdownOLD
                            value={selectedDriverId}
                            onSelect={({ value }) => setSelectedDriverId(value)}
                            options={props.drivers.map((driver) => ({
                                label: getDriverDisplayName(driver),
                                value: driver.mt_driver_id || "",
                            }))}
                        />

                        <Input
                            type={"time"}
                            value={startTime}
                            onChange={setStartTime}
                            trailingIcon={faClock}
                            placeholder={t("fleetPlanner.startTimePlaceholder")}
                        />

                        {props.isNewTourColumn && props.filterDate ? (
                            <Button
                                variant="primary"
                                label={t("createTour.createTourLabel")}
                                onClick={() =>
                                    createTourHandler({
                                        preferredDriverId: selectedDriverId,
                                        date: dateToString(props.filterDate),
                                        time: startTime,
                                        stopDraftIds: props.stops.map(
                                            (s) => s.id
                                        ),
                                    })
                                }
                                isLoading={isCreatingTour}
                            />
                        ) : (
                            <Button
                                variant="primary"
                                label={t("fleetPlanner.updateTour")}
                                onClick={() => {
                                    if (!props.tour) return;
                                    updateTour({
                                        tour_id: props.tour.tour_id,
                                        date: props.tour.date || "",
                                        time: startTime,
                                        preferred_driver_id: selectedDriverId,
                                    });
                                }}
                            />
                        )}
                    </div>
                    <div
                        className="content"
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                    >
                        {props.stops.map((stop, i) => (
                            <StopCardDraggable
                                key={stop.id}
                                id={stop.id.toString()}
                                index={i}
                                stop={stop}
                                runningWeight={runningWeights[i]}
                                eta={dateToString(
                                    addMinutes(
                                        stringToDate(
                                            (props.tour?.date || "2020-01-01") +
                                                "T" +
                                                startTime,
                                            {
                                                localTimezone: true,
                                            }
                                        )!,
                                        stopsDurationsMap[stop.id]
                                    ),
                                    {
                                        onlyTime: true,
                                    }
                                )}
                            />
                        ))}
                        {props.stops.length === 0 && (
                            <div className="empty-placeholder">
                                <p className="text-xs">
                                    {t("fleetPlanner.emptyPlaceholderText")}
                                </p>
                            </div>
                        )}
                        {provided.placeholder}
                    </div>
                </div>
            )}
        </Droppable>
    );
}

export default TourColumn;
