import { faCircle } from "@fortawesome/pro-solid-svg-icons";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Button from "../../components/buttons/Button";
import { StopDraft, StopDraftsTour } from "../../shared/types/api";
import { ReduxState } from "../../shared/types/redux";
import { DEFAULT_CENTER, STOP_DRAFT_TYPE } from "../../shared/values/enums";
import { tourColors } from "../../shared/values/lists";
import Map from "../Map";
import TourMarkers from "../markers/TourMarkers";
import "./style.scss";

type Props = {
    tours: StopDraftsTour[];
    stops?: StopDraft[];
};

function ToursMap(props: Props) {
    const { t } = useTranslation();
    const { user } = useSelector((state: ReduxState) => state.auth);
    const [focusedTourId, setFocusedTourId] = useState<number | null>(null);

    return (
        <div className="tours-map">
            <div className="controls">
                {props.stops && props.stops.length > 0 && (
                    <Button
                        key={"stops"}
                        variant={"secondary"}
                        label={t("bigVolume.plannedStops")}
                        onClick={() => setFocusedTourId(-1)}
                        leadingIcon={faCircle}
                        leadingIconColor={tourColors[tourColors.length - 1]}
                    />
                )}

                {props.tours.map((tour, i) => (
                    <Button
                        key={tour.tour_id}
                        variant={"secondary"}
                        label={tour.preferred_driver}
                        onClick={() => setFocusedTourId(tour.tour_id)}
                        leadingIcon={faCircle}
                        leadingIconColor={tourColors[i % tourColors.length]}
                    />
                ))}
            </div>
            <Map
                mapId="6849f94e371de149"
                defaultCenter={
                    user?.location_entity?.lat && user?.location_entity?.lng
                        ? {
                              lat: user.location_entity.lat,
                              lng: user.location_entity.lng,
                          }
                        : DEFAULT_CENTER
                }
                defaultZoom={12}
                showTrafficToggle
            >
                {props.tours.map((tour, i) => (
                    <TourMarkers
                        key={tour.tour_id}
                        points={tour.stops.map((stop, i) => ({
                            address: stop.to_location,
                            label: `${i + 1}`,
                            location: {
                                lat: +stop.lat!,
                                lng: +stop.lng!,
                            },
                            icon:
                                stop.stop_type_id === STOP_DRAFT_TYPE.Pickup
                                    ? "plane-departure"
                                    : "race-flag",
                        }))}
                        disableFitBounds={focusedTourId !== tour.tour_id}
                        boundsPadding={50}
                        directionsColor={tourColors[i % tourColors.length]}
                    />
                ))}

                {props.stops && (
                    <TourMarkers
                        key={"stops"}
                        points={props.stops.map((stop, i) => ({
                            address: stop.to_location,
                            label: `${i + 1}`,
                            location: {
                                lat: +stop.lat!,
                                lng: +stop.lng!,
                            },
                            icon:
                                stop.stop_type_id === STOP_DRAFT_TYPE.Pickup
                                    ? "plane-departure"
                                    : "race-flag",
                        }))}
                        disableFitBounds={focusedTourId !== -1}
                        boundsPadding={50}
                        directionsColor={tourColors[tourColors.length - 1]}
                    />
                )}
            </Map>
        </div>
    );
}

export default ToursMap;
