import { useTranslation } from "react-i18next";
import "./style.scss";

type Props = {
    style?: React.CSSProperties;
};

function Beta(props: Props) {
    const { t } = useTranslation();

    return (
        <span className="beta-icon text-xs" style={props.style}>
            {t("general.beta")}
        </span>
    );
}

export default Beta;
