import Map from "../Map";
import { useMemo, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import ButtonOLD from "../../components/buttons/ButtonOLD";
import TextButton from "../../components/buttons/TextButton";
import usePanToMapCenter from "../../hooks/functionality/usePanToMapCenter";
import { Driver } from "../../shared/types/api";
import { ReduxState } from "../../shared/types/redux";
import { getDriverDisplayName } from "../../shared/utility/misc";
import { DEFAULT_CENTER } from "../../shared/values/enums";
import DriverMarkers from "../markers/DriverMarkers";
import "./style.scss";

type Props = {
    drivers: Driver[];
    onUpdateDrivers: () => void;
    isUpdatingDrivers: boolean;
};

function DriversMap(props: Props) {
    const { t } = useTranslation();
    const { user } = useSelector((state: ReduxState) => state.auth);
    const { settings } = useSelector((state: ReduxState) => state.fleetPlanner);
    const [mapCenter, setMapCenter] = useState<google.maps.LatLng | undefined>(
        undefined
    );

    usePanToMapCenter(mapCenter || undefined);

    const activeDrivers = useMemo(
        () =>
            props.drivers.filter(
                (driver) => driver.current_lat && driver.current_lng
            ),
        [props.drivers]
    );
    const inactiveDrivers = useMemo(
        () =>
            props.drivers.filter(
                (driver) => !driver.current_lat || !driver.current_lng
            ),
        [props.drivers]
    );

    return (
        <div className="drivers-map">
            <Map
                mapId="drivers-map"
                defaultCenter={
                    user?.location_entity?.lat && user?.location_entity?.lng
                        ? {
                              lat: user.location_entity.lat,
                              lng: user.location_entity.lng,
                          }
                        : DEFAULT_CENTER
                }
                defaultZoom={12}
                showTrafficToggle
            >
                <DriverMarkers drivers={activeDrivers} />
            </Map>
            <div className="drivers-list">
                <div className="controls">
                    <ButtonOLD
                        label={t("drivers.updateMap")}
                        color="secondary"
                        onClick={props.onUpdateDrivers}
                        isLoading={props.isUpdatingDrivers}
                        short
                    />
                </div>
                {!!activeDrivers.length && (
                    <section>
                        <p>{t("drivers.activeDrivers")}</p>
                        {activeDrivers.map((driver) => (
                            <TextButton
                                key={driver.id}
                                label={getDriverDisplayName(driver, {
                                    showOrganization:
                                        !!settings.showAllDriversInCompany,
                                })}
                                color="var(--text-color)"
                                onClick={() =>
                                    setMapCenter(
                                        new google.maps.LatLng(
                                            +driver.current_lat!,
                                            +driver.current_lng!
                                        )
                                    )
                                }
                            />
                        ))}
                    </section>
                )}
                {!!inactiveDrivers.length && (
                    <section>
                        <p>{t("drivers.inactiveDrivers")}</p>
                        {inactiveDrivers.map((driver) => (
                            <TextButton
                                key={driver.id}
                                label={getDriverDisplayName(driver, {
                                    showOrganization:
                                        !!settings.showAllDriversInCompany,
                                })}
                                color="var(--text-color)"
                                onClick={() =>
                                    toast.error(
                                        t("errorMessage.inactiveDriver")
                                    )
                                }
                            />
                        ))}
                    </section>
                )}
            </div>
        </div>
    );
}

export default DriversMap;
