import { ReactElement, useCallback, useMemo } from "react";
import IconOLD from "../../components/UI/IconOLD";
import { IconType } from "../../shared/types/internal";
import "./style.scss";

type Column<T> = {
    key: keyof T | string;
    title: string | ReactElement;
    getValue?: (item: T, column: Column<T>) => string | number;
    render?: (item: T, column: Column<T>) => ReactElement | null;
    hide?: boolean;
    onSort?: (column: Column<T>) => void;
    sortIcon?: IconType;
};

type Props<T> = {
    data: T[];
    columns: Column<T>[];
    maxHeight?: string;
};

function Table<T>(props: Props<T>) {
    const tableClasses = ["table"];
    if (props.maxHeight) {
        tableClasses.push("scrollable");
    }

    const getValueToDisplay = useCallback((item: T, column: Column<T>) => {
        if (column.render) {
            return column.render(item, column);
        }

        if (column.getValue !== undefined) {
            return column.getValue(item, column);
        }

        return item[column.key as keyof typeof item] as string;
    }, []);

    const headers = useMemo(
        () =>
            props.columns
                .filter((column) => !column.hide)
                .map((column, index) => (
                    <th key={`head-cell-${index}`}>
                        <div
                            className="table-head-wrapper text-xs"
                            style={{
                                cursor: column.onSort ? "pointer" : undefined,
                            }}
                            onClick={() => column.onSort?.(column)}
                        >
                            {column.title}{" "}
                            {column.onSort && (
                                <IconOLD type={column.sortIcon || "seedling"} />
                            )}
                        </div>
                    </th>
                )),
        [props.columns]
    );

    const rows = useMemo(
        () =>
            props.data.map((item, i) => (
                <tr key={`row-${i}`}>
                    {props.columns
                        .filter((column) => !column.hide)
                        .map((column, j) => {
                            const value = getValueToDisplay(item, column);
                            return <td key={`cell-${j}`}>{value}</td>;
                        })}
                </tr>
            )),
        [getValueToDisplay, props.columns, props.data]
    );

    return (
        <div
            className={tableClasses.join(" ")}
            style={{
                maxHeight: props.maxHeight,
            }}
        >
            <table>
                <thead>
                    <tr>{headers}</tr>
                </thead>
                <tbody>{rows}</tbody>
            </table>
        </div>
    );
}

export default Table;
