import { useQuery } from "@tanstack/react-query";
import { useMap, useMapsLibrary } from "@vis.gl/react-google-maps";
import { useEffect, useMemo, useState } from "react";

function useDirections({
    hide = false,
    path,
    directionsColor,
    zIndex,
}: {
    hide?: boolean;
    path: google.maps.LatLngLiteral[];
    directionsColor?: string;
    zIndex?: number;
}) {
    const map = useMap();
    const routesLibrary = useMapsLibrary("routes");
    const [directionsService, setDirectionsService] =
        useState<google.maps.DirectionsService | null>(null);
    const [directionsRenderer, setDirectionsRenderer] =
        useState<google.maps.DirectionsRenderer | null>(null);
    const [pathDurationInMinutes, setPathDurationInMinutes] = useState(0);

    const { data: directionsResponse } = useQuery({
        queryKey: ["directions", path] as const,
        queryFn: async ({ queryKey: [, path] }) => {
            if (path.length > 25) return null;

            const response = await directionsService!.route({
                origin: path[0],
                waypoints: path.slice(1, path.length - 1).map((point) => ({
                    location: point,
                    stopover: true,
                })),
                destination: path[path.length - 1],
                travelMode: google.maps.TravelMode.DRIVING,
                unitSystem: google.maps.UnitSystem.METRIC,
                provideRouteAlternatives: false,
            });

            return response;
        },
        enabled: !!directionsService && !hide,
    });

    const arrowIcon = useMemo(
        () => ({
            path: google.maps.SymbolPath?.FORWARD_OPEN_ARROW || "",
            strokeOpacity: 1,
            scale: 3,
            strokeColor: "#0f0f0f",
        }),
        []
    );

    const polylineOptions = useMemo<google.maps.PolylineOptions>(() => {
        return {
            strokeColor: directionsColor || "#0f0f0f80",
            strokeOpacity: 1,
            strokeWeight: 5,
            icons: [
                {
                    icon: arrowIcon,
                    offset: "50%",
                },
            ],
            zIndex,
        };
    }, [arrowIcon, directionsColor, zIndex]);

    useEffect(() => {
        if (!routesLibrary) return;

        setDirectionsService(new routesLibrary.DirectionsService());
        setDirectionsRenderer(
            new routesLibrary.DirectionsRenderer({
                preserveViewport: true,
                suppressMarkers: true,
            })
        );
    }, [routesLibrary]);

    useEffect(() => {
        if (!directionsRenderer) return;
        directionsRenderer.setOptions({
            polylineOptions,
        });
    }, [directionsRenderer, polylineOptions]);

    useEffect(() => {
        if (!map || !directionsRenderer) return;

        if (!directionsResponse || hide) {
            setPathDurationInMinutes(0);
            directionsRenderer.setMap(null);
            return;
        }

        setPathDurationInMinutes(
            directionsResponse.routes[0]?.legs.reduce(
                (acc, leg) => acc + (leg.duration?.value || 0),
                0
            ) / 60
        );

        directionsRenderer.setDirections(directionsResponse);
        directionsRenderer.setMap(map);

        return () => {
            directionsRenderer.setMap(null);
        };
    }, [directionsRenderer, directionsResponse, hide, map, path.length]);

    return { pathDurationInMinutes, directionsRenderer };
}

export default useDirections;
